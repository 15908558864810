
// App.jsx
 
import React from "react";
import { Link } from "react-router-dom";
import sample from "../850_0721.jpg";
 
const Sample = () => {
    return (
        <div>
        <Link to="/saple" className="backbutton">Tilbake</Link>
        <div className="imageframe">
        <div className="image">
            <img src={sample} alt="Monochrome samples" />
            <p className="caption"><span>Lower two rows, to the left:</span> Ordinary film/developer combo making grey positives. Corresponding successful combination of film/developer to the right.
            Middle row, far right: made from a colour negative.</p>
            <p className="caption"><span>The upper right corner:</span> Negative of 25 ISO. The year rings in the wooden beam can be counted and the wristwatch says about 10:50</p>
            <p className="caption"><span>Upper row, two pictures to the left:</span> Well exposed negatives with nice grainy feeling and details across practically entire tonal range</p>
        </div>
        </div>
        </div>
    );
};
 
export default Sample;