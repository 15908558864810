import React from "react";
// Importing Link from react-router-dom to 
// navigate to different end points.
import { Link } from "react-router-dom";
import Sample from "../page/Sample";
import sampleOriginal from "../850_0721_orig.jpg";

import pdf from "../2019-01-25-Finansavisen.pdf";
import pdf2 from "../2018-01-15-Finansavisen.pdf";

 
const Home = () => {
    return (
        <div>
        <div className="App">
      <header className="App-header">
       
    
      <h1>Iván Kverme</h1>
      
      <h2>Arbeidsprøver</h2>
        <div className="links">
            <p>Monochrome Samples</p>


            <div className="horisontal">
                <Link to="/sample">Forhåndsvis</Link>
                <a href={sampleOriginal} rel="noopener" download>Last ned original (18.2 mb)</a>
            </div>

            <p>Finansavisen - Sport & Fritid: Fullt spann for Aker-sjefen</p>
            <a href={pdf} rel="noopener" download>Last ned</a>

            <p>Finansavisen - På tørre never og våte poter</p>
            <a href={pdf2} rel="noopener" download>Last ned</a>
        </div>
      </header>
   
    
      </div>
          
    </div>
    );
};
 
export default Home;